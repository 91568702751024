
import peri7 from "../../../assets/img/PERIFERICOS/MICROFONO1.png";

export default function ProductDetails() {
  const product = {
    id: 7,
    name: "MICROFONO FIFINE A6V RGB WHITE",
    price: "S/170",
    description: [
      "Marca: Fifine",
      "Modelo: A6V RGB WHITE",
      "Conectividad: USB",
      "Frecuencia: 50Hz - 16kHz",
      "Iluminación RGB personalizable",
    ],
    image: peri7,
  };

  return (
    <div className="max-w-5xl mx-auto mt-10 pt-17 pl-1 pb-16 bg-white shadow-lg rounded-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Imagen del producto */}
        <div>
          <img
            src={product.image}
            alt={product.name}
            className="w-600 h-100 object-cover rounded-lg"
          />
        </div>

        {/* Detalles del producto */}
        <div className="flex flex-col justify-between">
          <div>
            <h1 className="text-4xl font-bold text-gray-800 mb-6">{product.name}</h1>
            <p className="text-3xl font-semibold mb-8" style={{ color: '#ef0008' }}>
              {product.price}
            </p>

            <ul className="list-disc pl-6 mb-6 space-y-3 text-lg text-gray-700">
              {product.description.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          {/* Botón de comprar */}
          <div>
            <a
              href={`https://wa.me/51991842137?text=${encodeURIComponent(
                "Hola, estoy interesado en el producto: MICROFONO FIFINE A6V RGB WHITE"
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-black text-white text-xl w-full py-4 rounded-lg shadow-md hover:bg-gray-800 transition inline-flex items-center justify-center text-center"
            >
              ¡LO QUIERO!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
