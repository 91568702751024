import Header from "components/home/Header";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import Incentives from "components/home/Incentives";
import SectionMonitores from "components/home/SectionMonitores";
import Ofertas from "components/home/Ofertas";
import SectionComputadoras from "components/home/SectionComputadoras";
import SectionOtros from "components/home/SectionOtros";


function Home() {
  return (
    <Layout>
      <Navbar />
      <div className="pt-28">
        <Header />
        <Incentives />
        <SectionMonitores />
        <Ofertas />
        <SectionComputadoras />
        <SectionOtros />
      </div>
      <Footer />
    </Layout>
  );
}
export default Home;
