import { useState } from "react";
import imagen1 from "../../assets/img/BANNERS/BANNER.png";
import imagen2 from "../../assets/img/BANNERS/imagen2.jpg";
import imagen3 from "../../assets/img/BANNERS/imagen3.jpg";
import imagen4 from "../../assets/img/BANNERS/imagen4.jpg";

const images = [imagen1, imagen2, imagen3, imagen4];

function Header() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); // Ciclo infinito
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1 // Vuelve a la última imagen si es la primera
    );
  };

  return (
    <main className="bg-gray-50">
      {/* Slider de imágenes */}
      <div className="relative mx-auto max-w-full overflow-hidden">
        <div
          className="flex transition-transform duration-700 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index + 1}`}
              className="w-full h-auto object-cover object-center"
            />
          ))}
        </div>

        {/* Botones del slider */}
        <button
          className="absolute top-1/2 left-4 -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full hover:bg-gray-700 md:left-6"
          onClick={prevSlide}
        >
          &#8249;
        </button>
        <button
          className="absolute top-1/2 right-4 -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full hover:bg-gray-700 md:right-6"
          onClick={nextSlide}
        >
          &#8250;
        </button>
      </div>

      {/* Sección de características */}
      <div className="bg-gray-100 py-8 mt-10">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {/* Feature 1 */}
            <div className="bg-white shadow-md rounded-lg p-6 text-center">
              <div className="text-4xl mb-4" style={{ color: "#ef0008" }}>
                <i className="fas fa-truck"></i>
              </div>
              <h4 className="text-xl font-bold mb-2">Envios Provincia</h4>
              <p className="text-gray-600">a Nivel Nacional</p>
            </div>

            {/* Feature 2 */}
            <div className="bg-white shadow-md rounded-lg p-6 text-center">
              <div className="text-4xl mb-4" style={{ color: "#ef0008" }}>
                <i className="fas fa-check"></i>
              </div>
              <h4 className="text-xl font-bold mb-2">Garantía</h4>
              <p className="text-gray-600">1 año</p>
            </div>

            {/* Feature 3 */}
            <div className="bg-white shadow-md rounded-lg p-6 text-center">
              <div className="text-4xl mb-4" style={{ color: "#ef0008" }}>
                <i className="fas fa-wallet"></i>
              </div>
              <h4 className="text-xl font-bold mb-2">Transferencias</h4>
              <p className="text-gray-600">Todos los Bancos sin costo</p>
            </div>

            {/* Feature 4 */}
            <div className="bg-white shadow-md rounded-lg p-6 text-center">
              <div className="text-4xl mb-4" style={{ color: "#ef0008" }}>
                <i className="fas fa-headset"></i>
              </div>
              <h4 className="text-xl font-bold mb-2">Tarjetas +5%</h4>
              <p className="text-gray-600">Aceptamos Visa Mastercard</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Header;
