import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";

import Error404 from "containers/erros/Error404";
import Home from "containers/pages/Home";
import Laptops from "containers/pages/Laptops";
import Periferico from "containers/pages/Perifericos";
import Nosotros from "containers/pages/Nosotros";
import Componentes from "containers/pages/Componentes";
import Computadoras from "containers/pages/Computadoras";
import PcGamer from "containers/pages/PcGamer";
import PcOficina from "containers/pages/PcOficina";

import DetalleLaptop1 from "containers/pages/DETALLESLAP/DetalleLaptop1";
import DetalleLaptop2 from "containers/pages/DETALLESLAP/DetalleLaptop2";
import DetalleLaptop3 from "containers/pages/DETALLESLAP/DetalleLaptop3";
import DetalleLaptop4 from "containers/pages/DETALLESLAP/DetalleLaptop4";
import DetalleLaptop5 from "containers/pages/DETALLESLAP/DetalleLaptop5";
import DetalleLaptop6 from "containers/pages/DETALLESLAP/DetalleLaptop6";

import DetallePcGamer1 from "containers/pages/DETALLESPCGAMER/DetallePcGamer1";
import DetallePcGamer2 from "containers/pages/DETALLESPCGAMER/DetallePcGamer2";
import DetallePcGamer3 from "containers/pages/DETALLESPCGAMER/DetallePcGamer3";
import DetallePcGamer4 from "containers/pages/DETALLESPCGAMER/DetallePcGamer4";
import DetallePcGamer5 from "containers/pages/DETALLESPCGAMER/DetallePcGamer5";
import DetallePcGamer6 from "containers/pages/DETALLESPCGAMER/DetallePcGamer6";
import DetallePcGamer7 from "containers/pages/DETALLESPCGAMER/DetallePcGamer7";
import DetallePcGamer8 from "containers/pages/DETALLESPCGAMER/DetallePcGamer8";



import DetallePcOficina1 from "containers/pages/DETALLESPCOFICINA/DetallePcOficina1";
import DetallePcOficina2 from "containers/pages/DETALLESPCOFICINA/DetallePcOficina2";
import DetallePcOficina3 from "containers/pages/DETALLESPCOFICINA/DetallePcOficina3";
import DetallePcOficina4 from "containers/pages/DETALLESPCOFICINA/DetallePcOficina4";
import DetallePcOficina5 from "containers/pages/DETALLESPCOFICINA/DetallePcOficina5";
import DetallePcOficina6 from "containers/pages/DETALLESPCOFICINA/DetallePcOficina6";


import DetalleComponente1 from "containers/pages/DETALLESCOMP/DetalleComponente1";
import DetalleComponente2 from "containers/pages/DETALLESCOMP/DetalleComponente2";
import DetalleComponente3 from "containers/pages/DETALLESCOMP/DetalleComponente3";
import DetalleComponente4 from "containers/pages/DETALLESCOMP/DetalleComponente4";
import DetalleComponente5 from "containers/pages/DETALLESCOMP/DetalleComponente5";
import DetalleComponente6 from "containers/pages/DETALLESCOMP/DetalleComponente6";
import DetalleComponente7 from "containers/pages/DETALLESCOMP/DetalleComponente7";
import DetalleComponente8 from "containers/pages/DETALLESCOMP/DetalleComponente8";
import DetalleComponente9 from "containers/pages/DETALLESCOMP/DetalleComponente9";
import DetalleComponente10 from "containers/pages/DETALLESCOMP/DetalleComponente10";
import DetalleComponente11 from "containers/pages/DETALLESCOMP/DetalleComponente11";
import DetalleComponente12 from "containers/pages/DETALLESCOMP/DetalleComponente12";


import DetallePeriferico1 from "containers/pages/DETALLESPERI/DetallePeri1";
import DetallePeriferico2 from "containers/pages/DETALLESPERI/DetallePeri2";
import DetallePeriferico3 from "containers/pages/DETALLESPERI/DetallePeri3";
import DetallePeriferico4 from "containers/pages/DETALLESPERI/DetallePeri4";
import DetallePeriferico5 from "containers/pages/DETALLESPERI/DetallePeri5";
import DetallePeriferico6 from "containers/pages/DETALLESPERI/DetallePeri6";
import DetallePeriferico7 from "containers/pages/DETALLESPERI/DetallePeri7";
import DetallePeriferico8 from "containers/pages/DETALLESPERI/DetallePeri8";
import DetallePeriferico9 from "containers/pages/DETALLESPERI/DetallePeri9";
import DetallePeriferico10 from "containers/pages/DETALLESPERI/DetallePeri10";
import DetallePeriferico11 from "containers/pages/DETALLESPERI/DetallePeri11";
import DetallePeriferico12 from "containers/pages/DETALLESPERI/DetallePeri12";
import DetallePeriferico13 from "containers/pages/DETALLESPERI/DetallePeri13";
import DetallePeriferico14 from "containers/pages/DETALLESPERI/DetallePeri14";
import DetallePeriferico15 from "containers/pages/DETALLESPERI/DetallePeri15";
import DetallePeriferico16 from "containers/pages/DETALLESPERI/DetallePeri16";



function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/* Error Display */}
          <Route path="*" element={<Error404 />} />
          {/* Home Display */}
          <Route path="/" element={<Home />} />
          <Route path="/laptops" element={<Laptops />} />
          <Route path="/pcgamer" element={<PcGamer />} />
          <Route path="/pcoficina" element={<PcOficina />} />
          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/componentes" element={<Componentes />} />
          <Route path="/computadoras" element={<Computadoras />} />
          <Route path="/perifericos" element={<Periferico />} />

          <Route path="/detallelaptop1" element={<DetalleLaptop1 />} />
          <Route path="/detallelaptop2" element={<DetalleLaptop2 />} />
          <Route path="/detallelaptop3" element={<DetalleLaptop3 />} />
          <Route path="/detallelaptop4" element={<DetalleLaptop4 />} />
          <Route path="/detallelaptop5" element={<DetalleLaptop5 />} />
          <Route path="/detallelaptop6" element={<DetalleLaptop6 />} />

          <Route path="/detallepcgamer1" element={<DetallePcGamer1 />} />
          <Route path="/detallepcgamer2" element={<DetallePcGamer2 />} />
          <Route path="/detallepcgamer3" element={<DetallePcGamer3 />} />
          <Route path="/detallepcgamer4" element={<DetallePcGamer4 />} />
          <Route path="/detallepcgamer5" element={<DetallePcGamer5 />} />
          <Route path="/detallepcgamer6" element={<DetallePcGamer6 />} />
          <Route path="/detallepcgamer7" element={<DetallePcGamer7 />} />
          <Route path="/detallepcgamer8" element={<DetallePcGamer8 />} />


          <Route path="/detallepcoficina1" element={<DetallePcOficina1 />} />
          <Route path="/detallepcoficina2" element={<DetallePcOficina2 />} />
          <Route path="/detallepcoficina3" element={<DetallePcOficina3 />} />
          <Route path="/detallepcoficina4" element={<DetallePcOficina4 />} />
          <Route path="/detallepcoficina5" element={<DetallePcOficina5 />} />
          <Route path="/detallepcoficina6" element={<DetallePcOficina6 />} />



          <Route path="/detallecomponente1" element={<DetalleComponente1 />} />
          <Route path="/detallecomponente2" element={<DetalleComponente2 />} />
          <Route path="/detallecomponente3" element={<DetalleComponente3 />} />
          <Route path="/detallecomponente4" element={<DetalleComponente4 />} />
          <Route path="/detallecomponente5" element={<DetalleComponente5 />} />
          <Route path="/detallecomponente6" element={<DetalleComponente6 />} />
          <Route path="/detallecomponente7" element={<DetalleComponente7 />} />
          <Route path="/detallecomponente8" element={<DetalleComponente8 />} />
          <Route path="/detallecomponente9" element={<DetalleComponente9 />} />
          <Route path="/detallecomponente10" element={<DetalleComponente10 />} />
          <Route path="/detallecomponente11" element={<DetalleComponente11 />} />
          <Route path="/detallecomponente12" element={<DetalleComponente12 />} />

          <Route path="/detalleperiferico1" element={<DetallePeriferico1 />} />
          <Route path="/detalleperiferico2" element={<DetallePeriferico2 />} />
          <Route path="/detalleperiferico3" element={<DetallePeriferico3 />} />
          <Route path="/detalleperiferico4" element={<DetallePeriferico4 />} />
          <Route path="/detalleperiferico5" element={<DetallePeriferico5 />} />
          <Route path="/detalleperiferico6" element={<DetallePeriferico6 />} />
          <Route path="/detalleperiferico7" element={<DetallePeriferico7 />} />
          <Route path="/detalleperiferico8" element={<DetallePeriferico8 />} />
          <Route path="/detalleperiferico9" element={<DetallePeriferico9 />} />
          <Route path="/detalleperiferico10" element={<DetallePeriferico10 />} />
          <Route path="/detalleperiferico11" element={<DetallePeriferico11 />} />
          <Route path="/detalleperiferico12" element={<DetallePeriferico12 />} />
          <Route path="/detalleperiferico13" element={<DetallePeriferico13 />} />
          <Route path="/detalleperiferico14" element={<DetallePeriferico14 />} />
          <Route path="/detalleperiferico15" element={<DetallePeriferico15 />} />
          <Route path="/detalleperiferico16" element={<DetallePeriferico16 />} />


        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
