import pc1 from "../../assets/img/Computadoras/PCGAMER1.png";
import pc2 from "../../assets/img/Computadoras/PCGAMER2.png";
import pc3 from "../../assets/img/Computadoras/PCGAMER3.png";
import pc4 from "../../assets/img/Computadoras/PCGAMER4.png";
import pc5 from "../../assets/img/Computadoras/PCGAMER5.png";
import pc6 from "../../assets/img/Computadoras/PCGAMER6.png";
import { Link } from "react-router-dom";

export default function UseCases() {
  const products = [
    { id: 1, title: "COMBO APEX", stock: 2, price: "S/2599", image: pc1 },
    { id: 2, title: "COMBO ASSASINS", stock: 5, price: "S/4199", image: pc2 },
    { id: 3, title: "COMBO CYBERPUNK", stock: 3, price: "S/5399", image: pc3 },
    { id: 4, title: "COMBO DIABLO", stock: 2, price: "S/2599", image: pc4 },
    { id: 5, title: "COMBO DOTA", stock: 4, price: "S/1899", image: pc5 },
    { id: 6, title: "COMBO FALL GUYS", stock: 2, price: "S/2799", image: pc6 },
  ];

  return (
    <div className="bg-white-100">
      <div className="container bg-gray-100 mx-auto px-8 py-10">
        <h2 className="text-2xl font-bold mb-8">Nuestras computadoras:</h2>

        <div className="flex overflow-x-auto gap-6">
          {products.map((product) => (
            <div
              key={product.id}
              className="flex-none w-64 bg-white shadow-md rounded-lg overflow-hidden flex flex-col"
            >
              <div className="relative">
                <img
                  src={product.image}
                  alt={product.title}
                  className="h-48 w-full object-cover"
                />

                {/* Estilo para el stock */}
                <span className="absolute top-2 left-2 bg-gray-400 text-black text-xs font-bold px-2 py-1 rounded">
                  Stock: {product.stock}
                </span>
              </div>
              <div className="p-4 flex flex-col h-full justify-between">
                <h3 className="text-sm font-semibold text-gray-800 hover:text-blue-500">
                  {product.title}
                </h3>
                <div className="flex justify-between items-center mt-auto">
                  <span className="text-lg font-bold text-gray-800">
                    {product.price}
                  </span>
                  {/* Botón de agregar con color negro */}
                  <button
  className="bg-black text-white text-sm px-4 py-2 rounded-lg hover:bg-gray-800"
>
  <Link
    to={`/detallepcgamer${product.id}`} // Usa Link para la navegación interna
    className="text-white"
  >
    Ver más
  </Link>

                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
