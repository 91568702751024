import { Link } from "react-router-dom";
import pcgamer1 from "../../assets/img/Computadoras/PCGAMER1.png";
import pcgamer2 from "../../assets/img/Computadoras/PCGAMER2.png";
import pcgamer3 from "../../assets/img/Computadoras/PCGAMER3.png";
import pcgamer4 from "../../assets/img/Computadoras/PCGAMER4.png";
import pcgamer5 from "../../assets/img/Computadoras/PCGAMER5.png";
import pcgamer6 from "../../assets/img/Computadoras/PCGAMER6.png";
import pcgamer7 from "../../assets/img/Computadoras/PCGAMER7.png";
import pcgamer8 from "../../assets/img/Computadoras/PCGAMER8.png";

export default function SectionOtros() {
  const products = [
    { id: 1, description: "COMBO APEX", price: "S/2599", image: pcgamer1 },
    { id: 2, description: "COMBO ASSASINS", price: "S/4199", image: pcgamer2 },
    { id: 3, description: "COMBO CYBERPUNK", price: "S/5399", image: pcgamer3 },
    { id: 4, description: "COMBO DIABLO", price: "S/2599", image: pcgamer4 },
    { id: 5, description: "COMBO DOTA", price: "S/1899", image: pcgamer5 },
    { id: 6, description: "COMBO FALL GUYS", price: "S/2799", image: pcgamer6 },
    { id: 7, description: "COMBO WUKONG", price: "S/99", image: pcgamer7 },
    { id: 8, description: "COMBO VALORANT JETT", price: "S/3799", image: pcgamer8 },
  ];

  return (
    <div className="product-list pt-20 pb-20">
      <div className="container bg-gray-100 mx-auto px-8 py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map((product) => (
            <div key={product.id} className="product-list-box">
              <div className="product-list-item bg-white shadow-lg rounded-lg p-4 h-[500px] flex flex-col justify-between">
                <div className="product-list-img mb-4">
                  <Link to={`/detallepcgamer${product.id}`}>
                    <img
                      className="w-full h-[300px] object-contain rounded"
                      src={product.image}
                      alt={product.description}
                    />
                  </Link>
                </div>
                <div className="product-list-content flex flex-col justify-between h-full">
                  <h4 className="text-lg font-semibold mb-2 text-gray-800 truncate">
                    {product.description}
                  </h4>
                  <div className="product-list-price text-lg font-bold text-gray-800">
                    <span>{product.price}</span>
                  </div>
                </div>
                <Link
                  to={`/detallepcgamer${product.id}`}
                  className="mt-4 w-full bg-black text-white text-sm px-6 py-3 rounded-lg shadow-md hover:bg-gray-800 transition inline-flex items-center justify-center"
                >
                  Ver más
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
