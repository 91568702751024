import peri1 from "../../assets/img/PERIFERICOS/AUDIFONOS1.png";
import peri2 from "../../assets/img/PERIFERICOS/AUDIFONOS2.png";
import peri3 from "../../assets/img/PERIFERICOS/AUDIFONOS3.png";
import peri4 from "../../assets/img/PERIFERICOS/AUDIFONOS4.png";
import peri5 from "../../assets/img/PERIFERICOS/COMBO1.png";
import peri6 from "../../assets/img/PERIFERICOS/COMBO2.png";
import peri7 from "../../assets/img/PERIFERICOS/MICROFONO1.png";
import peri8 from "../../assets/img/PERIFERICOS/MICROFONO2.png";
import peri9 from "../../assets/img/PERIFERICOS/TECLADO2.png";
import peri10 from "../../assets/img/PERIFERICOS/TECLADO4.png";
import peri11 from "../../assets/img/PERIFERICOS/TECLADO6.png";
import peri12 from "../../assets/img/PERIFERICOS/TECLADO7.png";
import peri13 from "../../assets/img/PERIFERICOS/MOUSE1.png";
import peri14 from "../../assets/img/PERIFERICOS/MOUSE2.png";
import peri15 from "../../assets/img/PERIFERICOS/MOUSE3.png";
import peri16 from "../../assets/img/PERIFERICOS/MOUSE4.png";
import { Link } from "react-router-dom";

export default function SectionOtros() {
  const products = [
    { id: 1, description: "AURICULARES FIFINE H6 BLACK", price: "S/198", image: peri1 },
    { id: 2, description: "AURICULARES REDRAGON ZEUS X RGB H510-RGB WHITE", price: "S/267", image: peri2 },
    { id: 3, description: "AURICULARES LOGITECH G335 WHITE", price: "S/270", image: peri3 },
    { id: 4, description: "AURICULARES T-DAGGER SONA T RGH304 BLACK", price: "S/113", image: peri4 },
    { id: 5, description: "KIT MOUSE Y TECLADO INALAMBRICO TEROS TE-4061N", price: "S/50", image: peri5 },
    { id: 6, description: "COMBO 4 EN 1 TE 4063N", price: "S/120", image: peri6 },
    { id: 7, description: "MICROFONO FIFINE A6V RGB WHITE", price: "S/170", image: peri7 },
    { id: 8, description: "MICROFONO FIFINE A8 AMPLIGAME BLACK RGB", price: "S/250", image: peri8 },
    { id: 9, description: "TECLADO REDRAGON KUMARA BLACK K552RGB, SPANISH BLUE SWITCH", price: "S/200", image: peri9 },
    { id: 10, description: "TECLADO REDRAGON HARPE PRO SPANISH HARPE", price: "S/119", image: peri10 },
    { id: 11, description: "TECLADO REDRAGON KUMARA BLACK K552RGB, SPANISH RED SWITCH", price: "S/200", image: peri11 },
    { id: 12, description: "TECLADO REDRAGON KUMARA WHITE K552W-RGB, SPANISH BLUE SWITCH", price: "S/200", image: peri12 },
    { id: 13, description: "MOUSE GAMING ANTRYX KURTANA WHITE", price: "S/99", image: peri13 },
    { id: 14, description: "MOUSE GAMING REDRAGON COBRA M711", price: "S/89", image: peri14 },
    { id: 15, description: "MOUSE GAMING ANTRYX KURTANA BLACK", price: "S/99", image: peri15 },
    { id: 16, description: "MOUSE GAMING LOGITECH G203", price: "S/130", image: peri16 },
  ];

  return (
    <div className="product-list pt-20 pb-20">
      <div className="container bg-gray-100 mx-auto px-8 py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map((product) => (
            <div key={product.id} className="product-list-box">
              <div className="product-list-item bg-white shadow-lg rounded-lg p-4 h-[500px] flex flex-col justify-between">
                <div className="product-list-img mb-4">
                  <Link to={`/detalleperiferico${product.id}`}>
                    <img
                      className="w-full h-[300px] object-contain rounded"
                      src={product.image}
                      alt={product.description}
                    />
                  </Link>
                </div>
                <div className="product-list-content flex flex-col justify-between h-full">
                  <h4 className="text-lg font-semibold mb-2 text-gray-800 truncate">
                    {product.description}
                  </h4>
                  <div className="product-list-price text-lg font-bold text-gray-800">
                    <span>{product.price}</span>
                  </div>
                </div>
                <Link
                  to={`/detalleperiferico${product.id}`}
                  className="mt-4 w-full bg-black text-white py-2 rounded-lg shadow-md hover:bg-gray-800 transition text-center"
                >
                  Ver más
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
