
import pcgamer1 from "../../../assets/img/Computadoras/PCGAMER1.png";

export default function ProductDetails() {
  const product = {
    id: 1,
    name: "COMBO APEX",
    price: "S/2599.00",
    description: [
      "Procesador: AMD Ryzen 5 5500 3.6/42.GHz",
      "Motherboard: AsRock B450M-HDV AMD",
      "Memoria Ram: 2x8GB DDR4 Team Force Vulcan z Red",
      "SSD: Wester Digital Green 500GB M.2",
      "Case: Striker CBX 5013 White",
      "Tarjeta de Video: MS GTX 1650 XS GDDR6",
      "Fuente: Antryx B600W V3",
      "Monitor: Flat Teros TE-2712S 27¨ IPS FHD",
    ],
    combo: "Combo 4 en 1 TE 4063N",
    image: pcgamer1,
  };
  return (
    <div className="max-w-5xl mx-auto mt-10 pt-17 pl-1 pb-16 bg-white shadow-lg rounded-lg">
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
    {/* Imagen del producto */}
    <div>
      <img
        src={product.image}
        alt={product.name}
        className="w-600 h-100 object-cover rounded-lg"
      />
    </div>

    {/* Detalles del producto */}
    <div className="flex flex-col justify-between">
      <div>
        <h1 className="text-4xl font-bold text-gray-800 mb-6">{product.name}</h1>
        <p className="text-3xl font-semibold mb-8" style={{ color: '#ef0008' }}>
  {product.price}
</p>

        <ul className="list-disc pl-6 mb-6 space-y-3 text-lg text-gray-700">
          {product.description.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
      <p className="text-gray-700 font-semibold mb-6">
              <span className="text-gray-800 font-bold">Incluye:</span>{" "}
              {product.combo}
            </p>
      {/* Botón de comprar */}
      <div>
        <a
          href={`https://wa.me/51991842137?text=${encodeURIComponent(
            "Hola, estoy interesado en el COMBO: ASSASSIN'S CREED"
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          className="bg-black text-white text-xl w-full py-4 rounded-lg shadow-md hover:bg-gray-800 transition inline-flex items-center justify-center text-center"
        >
          ¡LO QUIERO!
        </a>
      </div>
    </div>
  </div>
</div>
  );
}
