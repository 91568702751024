
import monitor1 from "../../assets/img/MONITORES/monitor1.png";
import monitor2 from "../../assets/img/MONITORES/monitor2.png";
import monitor3 from "../../assets/img/MONITORES/monitor3.png";
import monitor4 from "../../assets/img/MONITORES/monitor4.png";
import monitor5 from "../../assets/img/MONITORES/monitor5.png";
import monitor6 from "../../assets/img/MONITORES/monitor6.png";

export default function UseCases() {
  return (
    <div className="bg-white-100">
      <div className="container bg-gray-100 mx-auto px-8 py-10">
        <h2 className="text-2xl font-bold mb-8">Monitores disponibles:</h2>

        <div className="flex overflow-x-auto gap-6">
          {[
            {
              title: "MONITOR TEROS 21.5' FHD 100HZ 1MS FLT",
              stock: 10,
              price: "S/300",
              image: monitor1,
            },
            {
              title: "MONITOR 24' TEROS 2412S FHD 100HZ 1MS FLAT",
              stock: 5,
              price: "S/360",
              image: monitor2,
            },
            {
              title: "MONITOR TEROS 27' 2766G FHD 180HZ 1MS CURVO",
              stock: 8,
              price: "S/690",
              image: monitor3,
            },
            {
              title: "MONITOR FLAT TEROS TE-2712S, 27' IPS, FLAT 100Hz",
              stock: 12,
              price: "S/450",
              image: monitor4,
            },
            {
              title: "MONITOR MSI OPTIX MAG240CR 23.6' 165HZ",
              stock: 4,
              price: "S/1090",
              image: monitor5,
            },
            {
              title: "MONITOR MSI OPTIX MAG270VC2 27' 165HZ",
              stock: 3,
              price: "S/1349",
              image: monitor6,
            },
          ].map((product, index) => (
            <div
              key={index}
              className="flex-none w-64 bg-white shadow-md rounded-lg overflow-hidden flex flex-col"
            >
              <div className="relative">
                <img
                  src={product.image}
                  alt={product.title}
                  className="h-48 w-full object-cover"
                />
                <span className="absolute top-2 left-2 bg-gray-300 text-black text-xs font-bold px-2 py-1 rounded">
                  Stock: {product.stock}
                </span>
              </div>
              <div className="p-4 flex flex-col h-full justify-between">
                <h3 className="text-sm font-semibold text-gray-800 hover:text-blue-500">
                  {product.title}
                </h3>
                <div className="flex justify-between items-center mt-auto">
                  <span className="text-lg font-bold text-gray-800">
                    {product.price}
                  </span>
                  <button
                    className="bg-black text-white text-sm px-4 py-2 rounded-lg hover:bg-gray-800"
                    onClick={() => {
                      const phone = "+51991842137"; // Tu número de WhatsApp
                      const message = `Hola, estoy interesado en el producto: ${product.title}`;
                      const url = `https://wa.me/${phone}?text=${encodeURIComponent(
                        message
                      )}`;
                      window.open(url, "_blank"); // Abre WhatsApp en una nueva pestaña
                    }}
                  >
                    <i className="fas fa-shopping-cart"></i> Comprar
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
