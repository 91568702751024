import { connect } from "react-redux";
import { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo_fnk from "assets/img/logo_fenk.png";
import "../../styles/index.css"; // Asegúrate de que la ruta sea correcta

function Navbar() {
  const [isOpen, setIsOpen] = useState(false); // Controla si el menú está abierto o cerrado
  const [isAnimating, setIsAnimating] = useState(false); // Controla si está en animación

  window.onscroll = function () {
    scrollFuntion();
  };

  function scrollFuntion() {
    if (document.getElementById("navbar")) {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("navbar").classList.add("shadow-navbar");
        document.getElementById("navbar").classList.add("bg-white");
      } else {
        document.getElementById("navbar").classList.remove("shadow-navbar");
        document.getElementById("navbar").classList.remove("bg-white");
      }
    }
  }

  // Función para manejar el cambio de estado del menú
  const toggleMenu = () => {
    if (isOpen) {
      // Si el menú está abierto, empieza la animación de cierre
      setIsAnimating(true);
      setTimeout(() => {
        setIsOpen(false); // Cierra el menú después de la animación
        setIsAnimating(false); // Detén la animación después de que se cierre
      }, 2500); // Duración de la animación (debe coincidir con la duración de la animación CSS)
    } else {
      // Si el menú está cerrado, ábrelo directamente sin animación
      setIsOpen(true);
    }
  };

  return (
    <nav
      id="navbar"
      className="w-full py-2 top-0 transition duration-300 ease-in-out z-40 bg-white fixed"
    >
      <div className="px-4 sm:px-6">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2">
          <Link to="/" className="ml-4 mt-2">
            <img
              src={logo_fnk}
              width={250}
              height={240}
              alt="logo de la empresa"
              className="responsive-logo"
            />
          </Link>

          {/* Botón de hamburguesa para móviles */}
          <div className="ml-4 mt-2 flex-shrink-0 sm:hidden">
            <button
              onClick={toggleMenu}
              className="text-black focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>

          {/* Menú desplegable para dispositivos grandes */}
          <div className="ml-4 mt-2 hidden sm:flex-shrink-0 sm:flex sm:items-center sm:space-x-4">
            <NavLink
              to="/"
              className="menu-link"
              style={({ isActive }) => ({
                borderBottom: isActive ? "2px solid #d40404" : "none",
              })}
            >
              Inicio
            </NavLink>
            <NavLink
              to="/laptops"
              className="menu-link"
              style={({ isActive }) => ({
                borderBottom: isActive ? "2px solid #d40404" : "none",
              })}
            >
              Laptops
            </NavLink>
            <NavLink
              to="/Computadoras"
              className="menu-link"
              style={({ isActive }) => ({
                borderBottom: isActive ? "2px solid #d40404" : "none",
              })}
            >
              Computadoras
            </NavLink>
            <NavLink
              to="/Componentes"
              className="menu-link"
              style={({ isActive }) => ({
                borderBottom: isActive ? "2px solid #d40404" : "none",
              })}
            >
              Componentes
            </NavLink>
            <NavLink
              to="/perifericos"
              className="menu-link"
              style={({ isActive }) => ({
                borderBottom: isActive ? "2px solid #d40404" : "none",
              })}
            >
              Perifericos
            </NavLink>

            <Link
              to="/Nosotros"
              className="inline-flex ml-12 items-center rounded-md border border-transparent bg-[#d40404] px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-black transition duration-300 ease-in-out"
            >
              Sobre Nosotros
            </Link>
          </div>
        </div>
      </div>

      {/* Menú desplegable en dispositivos móviles */}
      {isOpen && !isAnimating && (
        <div
          className="sm:hidden block absolute top-full left-0 right-0 bg-gray-200 z-50 transition-style-down"
        >
          <NavLink
            to="/"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
            style={({ isActive }) => ({
              borderBottom: isActive ? "2px solid #d40404" : "none",
            })}
          >
            Inicio
          </NavLink>
          <NavLink
            to="/laptops"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
            style={({ isActive }) => ({
              borderBottom: isActive ? "2px solid #d40404" : "none",
            })}
          >
            Laptops
          </NavLink>
          <NavLink
            to="/Computadoras"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
            style={({ isActive }) => ({
              borderBottom: isActive ? "2px solid #d40404" : "none",
            })}
          >
            Computadoras
          </NavLink>
          <NavLink
            to="/Componentes"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
            style={({ isActive }) => ({
              borderBottom: isActive ? "2px solid #d40404" : "none",
            })}
          >
            Componentes
          </NavLink>
          <NavLink
            to="/perifericos"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
            style={({ isActive }) => ({
              borderBottom: isActive ? "2px solid #d40404" : "none",
            })}
          >
            Perifericos
          </NavLink>

          <Link
            to="/Nosotros"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
          >
            Sobre Nosotros
          </Link>
        </div>
      )}

      {/* Menú cerrado con animación */}
      {isOpen && isAnimating && (
        <div
          className="sm:hidden block absolute top-full left-0 right-0 bg-gray-200 z-50 transition-style-up"
        >
          <NavLink
            to="/"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
            style={({ isActive }) => ({
              borderBottom: isActive ? "2px solid #d40404" : "none",
            })}
          >
            Inicio
          </NavLink>
          <NavLink
            to="/laptops"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
            style={({ isActive }) => ({
              borderBottom: isActive ? "2px solid #d40404" : "none",
            })}
          >
            Laptops
          </NavLink>
          <NavLink
            to="/Computadoras"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
            style={({ isActive }) => ({
              borderBottom: isActive ? "2px solid #d40404" : "none",
            })}
          >
            Computadoras
          </NavLink>
          <NavLink
            to="/Componentes"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
            style={({ isActive }) => ({
              borderBottom: isActive ? "2px solid #d40404" : "none",
            })}
          >
            Componentes
          </NavLink>
          <NavLink
            to="/perifericos"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
            style={({ isActive }) => ({
              borderBottom: isActive ? "2px solid #d40404" : "none",
            })}
          >
            Perifericos
          </NavLink>

          <Link
            to="/Nosotros"
            className="block text-lg p-4 border-b border-gray-200 hover:bg-gray-100"
          >
            Sobre Nosotros
          </Link>
        </div>
      )}
    </nav>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Navbar);
