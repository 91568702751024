import compo5 from "../../../assets/img/COMPONENTES/comp5.png";

export default function ProductDetails() {
  const product = {
    id: 5,
    name: "MOTHERBOARD MS A520M-A PRO S/V/L DDR4",
    price: "S/290.00",
    description: [
      "Marca: MSI",
      "Modelo: A520M-A PRO",
      "Chipset: AMD A520",
      "Socket: AM4",
      "Soporta procesadores Ryzen de 5000/4000/3000/2000",
      "Memoria: DDR4 hasta 4133 MHz (OC)",
      "Ranuras de memoria RAM: 2",
    ],
    image: compo5,
  };

  return (
    <div className="max-w-5xl mx-auto mt-10 pt-17 pl-1 pb-16 bg-white shadow-lg rounded-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <img src={product.image} alt={product.name} className="w-600 h-100 object-cover rounded-lg" />
        </div>

        <div className="flex flex-col justify-between">
          <div>
            <h1 className="text-4xl font-bold text-gray-800 mb-6">{product.name}</h1>
            <p className="text-3xl font-semibold mb-8" style={{ color: "#ef0008" }}>
              {product.price}
            </p>

            <ul className="list-disc pl-6 mb-6 space-y-3 text-lg text-gray-700">
              {product.description.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          <div>
            <a
              href={`https://wa.me/51991842137?text=${encodeURIComponent("Hola, estoy interesado en el producto: MS A520M-A PRO")}`}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-black text-white text-xl w-full py-4 rounded-lg shadow-md hover:bg-gray-800 transition inline-flex items-center justify-center text-center"
            >
              ¡LO QUIERO!
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
