import compo1 from "../../assets/img/COMPONENTES/comp1.png";
import compo2 from "../../assets/img/COMPONENTES/comp2.png";
import compo3 from "../../assets/img/COMPONENTES/comp3.png";
import compo4 from "../../assets/img/COMPONENTES/comp4.png";
import compo5 from "../../assets/img/COMPONENTES/comp5.png";
import compo6 from "../../assets/img/COMPONENTES/comp6.png";
import compo7 from "../../assets/img/COMPONENTES/comp7.png";
import compo8 from "../../assets/img/COMPONENTES/comp8.png";
import compo9 from "../../assets/img/COMPONENTES/comp9.png";
import compo10 from "../../assets/img/COMPONENTES/comp10.png";
import compo11 from "../../assets/img/COMPONENTES/comp11.png";
import compo12 from "../../assets/img/COMPONENTES/comp12.png";
import { Link } from "react-router-dom";

export default function SectionOtros() {
  const products = [
    { id: 1, description: "FUENTE ANTRYX B600W V3 ATX 2.3 BOX", price: "S/210", image: compo1 },
    { id: 2, description: "FUENTE ANTRYX B500W V3 ATX 2.3 BOX", price: "S/150", image: compo2 },
    { id: 3, description: "MOTHERBOARD ASROCK B450M-HDV R4.0 DDR4", price: "S/260", image: compo3 },
    { id: 4, description: "FUENTE DE PODER ANTRYX KIRIN 750W 80+ BRONZE", price: "S/318", image: compo4 },
    { id: 5, description: "MOTHERBOARD MS A520M-A PRO S/V/L DDR4", price: "S/290", image: compo5 },
    { id: 6, description: "MOTHERBOARD MSI B550M PRO-VDH WIFI", price: "S/420", image: compo6 },
    { id: 7, description: "PROCESADOR INTEL CORE I5 12400F 2,50 GHz", price: "S/590", image: compo7 },
    { id: 8, description: "PROCESADOR INTEL CORE I5-10400 2,90GHZ", price: "S/662", image: compo8 },
    { id: 9, description: "PROCESADOR INTEL CORE I3 12100F 3,30 GHz", price: "S/390", image: compo9 },
    { id: 10, description: "ENFRIAMIENTO LIQUIDO LE520 ARGB", price: "S/300", image: compo10 },
    { id: 11, description: "ENFRIAMIENTO LIQUIDO ENERMAX LIQMAX III 240 AIO RGB LGA1200", price: "S/390", image: compo11 },
    { id: 12, description: "ENFRIAMIENTO LIQUIDO ANTRYX TRITON 240C ARGB LGA1200", price: "S/500", image: compo12 },
  ];

  return (
    <div className="product-list pt-20 pb-20">
      <div className="container bg-gray-100 mx-auto px-8 py-10">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {products.map((product) => (
            <div key={product.id} className="product-list-box">
              <div className="product-list-item bg-white shadow-lg rounded-lg p-4 h-[500px] flex flex-col justify-between">
                <div className="product-list-img mb-4">
                  <Link to={`/detallecomponente${product.id}`}>
                    <img
                      className="w-full h-[300px] object-contain rounded"
                      src={product.image}
                      alt={product.description}
                    />
                  </Link>
                </div>
                <div className="product-list-content flex flex-col justify-between h-full">
                  <h4 className="text-lg font-semibold mb-2 text-gray-800 truncate">
                    {product.description}
                  </h4>
                  <div className="product-list-price text-lg font-bold text-gray-800">
                    <span>{product.price}</span>
                  </div>
                </div>
                <Link
                  to={`/detallecomponente${product.id}`}
                  className="mt-4 w-full bg-black text-white py-2 rounded-lg shadow-md hover:bg-gray-800 transition text-center"
                >
                  Ver más
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
