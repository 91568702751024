import { useNavigate } from "react-router-dom";
import mouse1 from "../../assets/img/PERIFERICOS/mouse1.1.png";
import mouse2 from "../../assets/img/PERIFERICOS/mouse2.1.png";
import mouse3 from "../../assets/img/PERIFERICOS/mouse3.1.png";

export default function SectionOtros() {
  const navigate = useNavigate(); // Usamos el hook para la navegación

  const handleNavigate = (id) => {
    navigate(`/detalleperiferico${id}`); // Redirige al producto con el ID correspondiente
  };

  return (
    <div className="product-list pt-20 pb-20">
      <div className="container bg-gray-100 mx-auto px-8 py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Primer producto */}
          <div className="product-list-box">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Logitech</h2>
            <div className="product-list-item bg-white shadow-lg rounded-lg p-4">
              <div className="product-list-img mb-4">
                <a href="/detalleperiferico16">
                  <img
                    className="w-full h-auto rounded"
                    src={mouse1}
                    alt="Mouse Logitech"
                  />
                </a>
              </div>
              <div className="product-list-content">
                <h4 className="text-lg font-semibold mb-2">
                  <a
                    href="/detalleperiferico16"
                    className="text-gray-800 hover:text-blue-600"
                  >
                    MOUSE LOGITECH G203 RGB BLACK G203
                  </a>
                </h4>
                <div className="product-list-price text-lg font-bold text-gray-800">
                  <span>S/130</span>
                </div>
              </div>
              <button
                onClick={() => handleNavigate(16)} // Usamos el hook para redirigir
                type="button"
                className="mt-4 w-full bg-black text-white text-sm px-6 py-3 rounded-lg shadow-md hover:bg-gray-800 transition inline-flex items-center justify-center"
              >
                Ver más
              </button>
            </div>
          </div>

          {/* Segundo producto */}
          <div className="product-list-box">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Redragon</h2>
            <div className="product-list-item bg-white shadow-lg rounded-lg p-4">
              <div className="product-list-img mb-4">
                <a href="/detalleperiferico14">
                  <img
                    className="w-full h-auto rounded"
                    src={mouse2}
                    alt="Mouse Razer"
                  />
                </a>
              </div>
              <div className="product-list-content">
                <h4 className="text-lg font-semibold mb-2">
                  <a
                    href="/detalleperiferico14"
                    className="text-gray-800 hover:text-blue-600"
                  >
                    MOUSE REDRAGON COBRA BLACK M711
                  </a>
                </h4>
                <div className="product-list-price text-lg font-bold text-gray-800">
                  <span>S/89</span>
                </div>
              </div>
              <button
                onClick={() => handleNavigate(14)} // Usamos el hook para redirigir
                type="button"
                className="mt-4 w-full bg-black text-white text-sm px-6 py-3 rounded-lg shadow-md hover:bg-gray-800 transition inline-flex items-center justify-center"
              >
                Ver más
              </button>
            </div>
          </div>

          {/* Tercer producto */}
          <div className="product-list-box">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Antryx</h2>
            <div className="product-list-item bg-white shadow-lg rounded-lg p-4">
              <div className="product-list-img mb-4">
                <a href="/detalleperiferico15">
                  <img
                    className="w-full h-auto rounded"
                    src={mouse3}
                    alt="Kit Antryx"
                  />
                </a>
              </div>
              <div className="product-list-content">
                <h4 className="text-lg font-semibold mb-2">
                  <a
                    href="/detalleperiferico15"
                    className="text-gray-800 hover:text-blue-600"
                  >
                    MOUSE ANTRYX CHROME STORM KURTANA
                  </a>
                </h4>
                <div className="product-list-price text-lg font-bold text-gray-800">
                  <span>S/99</span>
                </div>
              </div>
              <button
                onClick={() => handleNavigate(15)} // Usamos el hook para redirigir
                type="button"
                className="mt-4 w-full bg-black text-white text-sm px-6 py-3 rounded-lg shadow-md hover:bg-gray-800 transition inline-flex items-center justify-center"
              >
                Ver más
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
