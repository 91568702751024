import { connect } from "react-redux";
import logo_fnk from "assets/img/logo_fenk.png";
import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-gray-300 py-6">
      <div className="container mx-auto px-4">
        {/* Sección principal */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          {/* Columna 1: Logo */}
          <div className="md:col-span-1 flex flex-col items-start">
            <img src={logo_fnk} alt="Logo Empresa" className="w-32 mb-4" />
            <p className="text-sm">
              Empresa peruana dedicada a la venta de productos tecnológicos y
              soluciones informáticas.
            </p>
          </div>

          {/* Columna 2 */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">
              Nuestra Empresa
            </h3>
            <ul className="space-y-2">
              <li>
                <a href="/nosotros" className="hover:text-gray-400">
                  Sobre nosotros
                </a>
              </li>
              <li>
                <a href="/servicios" className="hover:text-gray-400">
                  Servicios
                </a>
              </li>
              <li>
                <a href="/careers" className="hover:text-gray-400">
                  Carreras
                </a>
              </li>
              <li>
                <a href="/blog" className="hover:text-gray-400">
                  Blog
                </a>
              </li>
            </ul>
          </div>

          {/* Columna 3 */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">Soporte</h3>
            <ul className="space-y-2">
              <li>
                <a href="/help" className="hover:text-gray-400">
                  Centro de ayuda
                </a>
              </li>
              <li>
                <a href="/contact" className="hover:text-gray-400">
                  Contáctanos
                </a>
              </li>
              <li>
                <a href="/faq" className="hover:text-gray-400">
                  Preguntas frecuentes
                </a>
              </li>
              <li>
                <a href="/terms" className="hover:text-gray-400">
                  Términos y condiciones
                </a>
              </li>
            </ul>
          </div>

          {/* Columna 4 */}
          <div>
            <h3 className="text-xl font-semibold text-white mb-4">Síguenos</h3>
            <ul className="flex space-x-4">
              <li>
                <a
                  href="https://www.facebook.com/GrupoFenk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-400"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@fenk_computer?lang=es"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-400"
                >
                  <i className="fab fa-tiktok"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/jc_fenk/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-400"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="mailto:servicios.fenk@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-400"
                >
                  <i className="fas fa-envelope"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/51991842137"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-400"
                >
                  <i className="fab fa-whatsapp"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Línea divisoria */}
        <div className="border-t border-gray-700 mt-6 pt-4">
          <p className="text-center text-sm">
            &copy; {new Date().getFullYear()} FENK COMPUTER. Todos los derechos
            reservados.
          </p>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Footer);
